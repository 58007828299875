<template>
    <v-container class="pa-0 ma-0">
        <Datatable
                   api-endpoint="datatables/erp/units/"
                   :datatable-headers="datatableHeaders"
                    no-excel-export
                   :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
                   :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
                   :show-toggle-status="this.$store.getters['permissions/checkPermission']('ware_edit')"
                   @deleteEntries="deleteEntries" @deleteEntry="deleteEntry"
                   @editEntry="editEntry"
                   ref="unitDatatable"
                   show-delete-buttons
                   show-edit-buttons
                   show-select-checkbox
        ></Datatable>
    </v-container>
</template>

<script>

    import {Events} from "../../../../plugins/events";
    import Datatable from "../../../datatable/Datatable";
    import {ENDPOINTS} from "../../../../config";

    export default {
        name: "UnitsComponenent",
        components:{Datatable},
        data(){
            return{
                id:null,
            }
        },
        computed: {
            datatableHeaders() {
                return [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: false,
                    },
                    {text: this.$t('erp.lang_unitName'), value: 'unitName',sort: "asc"},
                    {text: this.$t('erp.lang_unitSymbol'), value: 'unitSign'},
                ];
            },
        },
        methods:{
            deleteData(idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t("erp.lang_deleteUnitHead"),
                    text: this.$t("erp.lang_deleteUnitBody"),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post("delete/erp/unit/", {
                            unitID: idsToDelete,
                        }).then((res) => {
                            if (res.status===200) {
                                Events.$emit("showSnackbar", {
                                    message: this.$t("erp.lang_deleteUnitConfirm"),
                                    color: "success"
                                });
                                self.$refs.unitDatatable.getDataFromApi();
                                self.$refs.unitDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading,
                });
            },
            deleteEntry(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            deleteEntries(entries) {
                this.deleteData(entries);
            },
            editEntry(entry){
                this.id = entry.id;
                this.$router.push({name: 'erp.settings.unitManagement.edit', params: {id: parseInt(this.id)}})
            }
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>