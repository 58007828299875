<template>
    <div>
        <page-title :heading="$t('erp.lang_unitManagement')"
                    :subheading="$t('erp.lang_unitManagement')"
                    :icon=icon
                    show-add-button
                    :permission-add="this.$store.getters['permissions/checkPermission']('additives')"
        ></page-title>

        <div class="app-main__inner">
            <units-componenent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle";
    import UnitsComponenent from "../../../../components/erp/settings/unitManagement/UnitsComponent";
    export default {
        name: "UnitManagement",
        components:{UnitsComponenent, PageTitle},
        data: () => ({
            icon: 'mdi mdi-tape-measure icon-gradient bg-tempting-azure',
        })

    }
</script>

<style scoped>

</style>